<template>
<div>
    <!-- navbar -->
    <Navbar />
    <div id="layoutSidenav">
        <Menu />
        <div id="layoutSidenav_content">
            <main>
                <!-- Main page content-->
                <div class="container-xl px-4 mt-5">
                    <!-- Custom page header alternative example-->
                    <div class="d-flex justify-content-between align-items-sm-center flex-column flex-sm-row mb-4">
                        <div class="me-4 mb-3 mb-sm-0">
                            <h1 class="mb-0">{{getLabelTitulo}} {{this.$store.state.user.user.nombre}}</h1>
                            <div class="small">
                                <span class="fw-500 text-primary" id="mes">{{currentMonth}}</span>
                                {{getCurrentDate}}
                            </div>
                        </div>

                    </div>
                    <!-- Illustration dashboard card example-->
                    <div class="card card-waves mb-4 mt-5">
                        <div class="card-body p-5">
                            <div class="row align-items-center justify-content-between">
                                <div class="col">
                                    <h2 class="text-primary">Aplicaci&oacute;n para Proveedoras y Proveedores</h2>
                                    <p class="text-gray-700">En esta web se van a poder realizar distintas operaciones, para facilitar la comunicaci&oacute;n con la empresa.</p>
                                </div>
                                <div class="col d-none d-lg-block mt-xxl-n4"><img class="img-fluid px-xl-4 mt-xxl-n5" src="assets/img/illustrations/statistics.svg" /></div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="row">
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card border-start-lg border-start-primary h-100" style="border-left-color:#6480b5!important;" @click="redirectHomeMenu('autogestion')">
                                <div class="card-body">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-grow-1">
                                            <div class="small fw-bold text-primary mb-1" style="color:#6480b5">Registraci&oacute;n</div>
                                            <div class="h5">Finalizada</div>
                                        </div>
                                        <div class="ms-2"><i class="fas fa-check-circle" style="color:#6480b5;font-size:35px;"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card border-start-lg border-start-secondary h-100" style="border-left-color:#52b69a !important;cursor:pointer;" @click="redirectHomeMenu('mensajes')">
                                <div class="card-body">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-grow-1">
                                            <div class="small fw-bold text-secondary mb-1" style="color:#52b69a !important">Cantidad de mensajes</div>
                                            <div class="h5">{{cantidadMensajes}}</div>
                                        </div>
                                        <div class="ms-2"><i class="fas fa-inbox" style="color:#52b69a;font-size:35px;"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card border-start-lg border-start-info h-100" style="border-left-color:#52b69a !important;cursor:pointer;" @click="redirectHomeMenu('mensajes')">
                                <div class="card-body">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-grow-1">
                                            <div class="small fw-bold text-info mb-1" style="color:#52b69a !important">Cantidad de mensajes sin leer</div>
                                            <div class="h5">{{notificacionesMensaje}}</div>
                                        </div>
                                        <div class="ms-2"><i class="fas fa-envelope" style="color:#52b69a;font-size:35px;"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-6 mb-4">
                            <div class="card border-start-lg border-start-success h-100" style="border-left-color:#ddb66a !important;cursor:pointer;" @click="redirectHomeMenu('notificaciones')">
                                <div class="card-body">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-grow-1">
                                            <div class="small fw-bold text-success mb-1" style="color:#ddb66a !important">Cantidad de notificaciones sin leer</div>
                                            <div class="h5">{{notificaciones}}</div>
                                        </div>
                                        <div class="ms-2"><i class="fas fa-bell" style="color:#ddb66a;font-size:35px;"></i></div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div> -->

                </div>
            </main>
        </div>
    </div>

</div>
</template>

<style scoped>
h2 {
    font-weight: 600;
    margin-top: 30px;
    margin-bottom: 30px;
}
</style>

<script>
import router from '@/router/index.js' // Vue router instance
import spinner from '../components/spinner'
import Vue from 'vue'
import Navbar from '../components/home/navbar/navbar'
import Menu from '../components/home/menu'
import $ from 'jquery'

export default {
    name: "Home",
    components: {
        spinner,
        Navbar,
        Menu

    },
    mounted: function () {
        this.addMenu();
    },
    methods: {
        addMenu() {
            document.body.classList.remove("sidenav-toggled");
        },
        redirectHomeMenu(data) {
            this.$store.dispatch("notificaciones/getNotificacionMenu");
            this.$store.dispatch("mensajes/getNotificacionMensaje");
            this.$store.commit("menu/SET_ACTIVO", "" + data);
            router.push("/" + data);
        },

    },
    computed: {
        currentMonth() {
            const dateObj = new Date();
            const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
                "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
            ];
            return monthNames[dateObj.getMonth()];
        },
        getCurrentDate() {
            const dateObj = new Date();
            const day = String(dateObj.getDate()).padStart(2, '0');
            const year = dateObj.getFullYear();
            return day + ', ' + year;
        },
        getLabelTitulo() {
            let now = new Date().getHours();
            if (6 < now && now < 12) {
                return "Buenos días";
            } else if (12 < now && now < 19) {
                return "Buenas tardes";
            } else {
                return "Buenas noches";
            }
        },
        notificacionesMensaje: {
            get() {
                return this.$store.state.mensajes.cantidadMensajeNotificacion;
            },
        },
        notificaciones: {
            get() {
                return this.$store.state.notificaciones.cantidadNotificacion;
            },
        },
        cantidadMensajes: {
            get() {
                return this.$store.state.mensajes.cantidadMensajes;
            }
        },
    },
};
</script>
